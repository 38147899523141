@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

a {
  color: #005581;
  text-decoration: underline;
}

a:hover {
  color: #002033;
  text-decoration: underline;
}

.navbar-default .navbar-nav > li > a {
  text-decoration: none;
}

p {
  font-family: "KievitProBook", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  display: block;
  clear: both;
}

p.kievit_pro_med {
  font-family: "KievitProMed", Arial, Helvetica, sans-serif;
}

p.kievit_pro_med.covid {
  font-weight: bold;
  color: red;
}

h2 {
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
    font-size: 31px;
    color: #333333;
    margin-top: 0px;
    line-height: 43px;
    margin-bottom: 5px;
    padding-bottom: 0px;
  }

a.back-btn {
  display: flex;
  color: #005581;
  text-decoration: underline;
  font-size: 16pt;
  font-family: "KievitProMed";
  margin-bottom: 10px;
}

.main-container .back-btn {
  background: #fff;
  color: #005581;
  display: flex;
  font-family: KievitProMed;
  font-size: 16pt;
  margin: 0;
  padding-left: 50px;
  padding-bottom: 50px;
  text-decoration: underline;
}

div.error, table tr.error {
  color: red;
  background-color: transparent !important;
}

/*(ADVANCED) SEARCH FORM PARTIALS*/
.cce-index-search-button {
  text-transform: uppercase;
  padding: 0 32px;
  font-family: "KievitProMed", Arial, Helvetica, sans-serif;
  margin-bottom: 0px;
  margin-top: 13px;
  vertical-align: top;
  font-size: 14px;
  border-radius: 8px;
  appearance: none;
  color: black;
  background-color: #fff;
}

.cce-advanced-search-form input[type="submit"] {
    margin-right: 48px;
}
.cce-search-form label {
    margin: 0;
  }

  .cce-search-form-input-container {
    display: inline-block;
    margin-right: 18px;
    vertical-align: top;
    color: black;
  }

  .cce-search-form-input-container label {
    color: black;
    font-weight: 400;
    display: inline-block;
  }

  .cce-search-form-input-container fieldset{
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  .cce-search-form-submit-container{
    display: inline-block;
    margin-top: 12px;
    vertical-align: top;
    width: min-content;
  }

  .cce-search-form-select select,
  .cce-search-form-textbox input {
    appearance: none;
    border-radius: 8px;
    background: transparent;
    padding: 10px 32px 10px 10px;
  }

  .cce-advanced-search-clear-button,
  .cce-advanced-search-form select,
  .cce-advanced-search-form input,
  .cce-search-form select,
  .cce-search-form input,
  .cce-notify-me-form input {
    appearance: none;
    height: 40px;
    border: 1px solid #808d98;
    border-radius: 8px;
    background: transparent;
    padding: 10px 32px 10px 10px;
    color: #171717;
    font-family: "KievitProRegular", Arial, Helvetica, sans-serif;
  }

  .cce-notify-me-form {
    margin-right: 15px;
  }

  .index-search-form-container
  .cce-advanced-search-form select,
  .index-search-form-container
  .cce-advanced-search-form label {
    color: #171717;
    font-weight: 400;
}

  .cce-advanced-search-clear-button,
  .cce-advanced-search-form input,
  .cce-search-form input {
    padding: 10px;
  }

  .cce-advanced-search-submit-row,
  .cce-notify-me-submit-row  {
    margin-top: 24px;
    text-align: center;
  }

  .cce-advanced-search-form input[type="submit"] {
    margin-right: 48px;
  }

  .cce-search-time-to {
    position: relative;
    top: 10px;
    right: 7px;
  }
  .cce-advanced-search-modal-container .cce-advanced-search-form select,
  .cce-advanced-search-modal-container .cce-advanced-search-form input,
  .cce-search-form select,
  .cce-search-form input {
    appearance: none;
    height: 40px;
    border: 1px solid #808d98;
    border-radius: 8px;
    background: transparent;
    padding: 10px 32px 10px 10px;
    color: black;
    margin-top: 5px;
    min-width: 300px;
    width: 100%;
    color: #171717;
    font-family: "KievitProRegular", Arial, Helvetica, sans-serif;
  }

  .cce-search-form input {
    padding: 10px;
  }

  .cce-advanced-search-modal-container .cce-advanced-search-clear-button,
  .cce-notify-me-modal-container .cce-notify-me-button,
  .cce-advanced-search-modal-container .cce-advanced-search-form input[type="submit"],
  .cce-search-form input[type="submit"] {
    text-transform: uppercase;
    padding: 0 32px;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
    margin-bottom: 8px;
    vertical-align: top;
    font-size: 14px;
    margin-right: 24px;
    margin-left: 24px;
    background: #005581;
    color: #fff;
    border-radius: 8px;
  }

  .cce-advanced-search-form input[type="submit"] {
    margin-right: 48px;
  }

  .cce-search-time-to {
    position: relative;
    top: 10px;
    right: 7px;
  }

  .cce-search-form-select select::-ms-expand {
    display: none;
  }

  .cce-search-form-select {
    position: relative;
    z-index: 1;
    height: 100px;
    width: 300px;
  }

  .cce-advanced-search-button {
    background: none;
    border: none;
    text-align: left;
    white-space: nowrap;
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    text-decoration: underline;
    font-family: "KievitProMed";
    color: #002033;
  }

  .no-results-message .cce-advanced-search-button {
    text-decoration: underline;
    font-size: 14pt;
    font-family: "KievitProMed";
    background: none;
    color: #002033;
  }

  .cce-search-form-control-icon-container {
    position: absolute;
    pointer-events: none;
    top: 32px;
    right: 16px;
  }

  .cce-search-form-control-icon {
    stroke: #808d98;
  }

  .cce-advanced-search-form
    .cce-search-form-row
    .cce-search-form-input-container {
    width: auto;
  }

  .cce-advanced-search-form-control-icon-container {
    position: absolute;
    top: 45px;
    right: 10px;
    pointer-events: none;
  }

  .cce-search-time-range-label {
    display: block;
  }

  .cce-advanced-search-modal-header-row,
  .cce-notify-me-modal-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
  }

  .cce-advanced-search-modal-title,
  .cce-notify-me-modal-title {
    margin: 0;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
    font-size: 20px;
  }

  .cce-advanced-search-modal-close-button,
  .cce-notify-me-modal-close-button {
    height: 20px;
    width: 20px;
    background-image: url("../../../public/images/icons/search/close.png");
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .cce-advanced-search-modal-content {
    background: white;
    padding: 32px;
    margin-top: 64px;
  }

  .cce-notify-me-modal-content {
    background: white;
    padding: 32px;
    margin-top: 100px;
    width: 700px;
  }

  .cce-notify-me-modal-container
    .cce-notify-me-modal-content
    .cce-advanced-search-form,
  .cce-advanced-search-modal-container
    .cce-advanced-search-modal-content
    .cce-advanced-search-form
    .cce-advanced-search-form-schedule-text,
  .cce-advanced-search-modal-container
    .cce-advanced-search-modal-content
    .cce-advanced-search-form {
    text-align: left;
    color: black;
    width: 100%;
  }
  .cce-advanced-search-modal-container
    .cce-advanced-search-modal-content
    .cce-advanced-search-form
    .cce-advanced-search-form-schedule-text {
    padding: 0;
    margin: 20px 0;
  }

  .cce-advanced-search-form-schedule-text {
    font-size: 16px;
  }

  .cce-advanced-search-modal-backdrop,
  .cce-notify-me-modal-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }

  .cce-advanced-search-modal-container,
  .cce-notify-me-modal-container  {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
  }

  .results-page-top-container .cce-search-form label,
  .results-page-top-container .cce-search-form select {
    color: #002033;
    font-size: 12pt;
    font-weight: normal;
  }

  .cce-search-form-row {
    margin-top: 24px;
  }

  .cce-advanced-search-modal-backdrop.closed,
  .cce-notify-me-modal-backdrop.closed,
  .cce-advanced-search-modal-container.closed {
    display: none;
  }

  label[for="start-time-advanced"],
  label[for="end-time-advanced"] {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  .cce-search-form-toggle,
  .cce-notify-me-form-toggle {
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 12px;
  }

  .cce-search-form-input-container legend,
  .cce-notify-me-form-input-container legend {
    margin-bottom: 10px;
    border: none;
    font-size: 16px;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
    font-weight: normal;
  }

  .cce-search-form-toggle label {
    position: relative;
    margin: 0;
    width: 100px;
    height: 66px;
  }

  .cce-notify-me-form-toggle label {
    position: relative;
    margin: 0;
    width: 120px;
    height: 66px;
  }

  .cce-search-form-toggle span,
  .cce-notify-me-form-toggle span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #cccccc;
    background: linear-gradient(#ffffff, #eeeeee);
  }

  .cce-search-form-toggle input:checked + span,
  .cce-search-form-toggle:hover span,
  .cce-notify-me-form-toggle input:checked + span,
  .cce-notify-me-form-toggle:hover span {
    border: 1px solid #1295d8;
  }

  .cce-search-form-toggle input:not(:checked) + span,
  .cce-search-form-toggle input:not(:checked) + span {
    border: 1px solid #cccccc;
  }

  .cce-search-form-toggle input,
  .cce-notify-me-form-toggle input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .cce-search-form-toggle input:checked + span,
  .cce-notify-me-form-toggle input:checked + span {
    color: #1295d8;
    background: white;
  }

  .cce-search-form-toggle input:focus + span,
  .cce-notify-me-form-toggle input:focus + span {
    border: 2px solid #1295d8;
  }

  .ucop-div-select.invalid .cce-search-form-control-icon {
    stroke: red;
  }

  .ucop-div-select.invalid .input-field-error-container {
    display: block;
    margin-top: 12px;
  }

  .results-page-top-container .ucop-div-select.invalid .input-field-error-container  {
    margin-top: 30px;
  }

  .input-field-error-container {
    display: none;
    color: red;
    font-size: 14px;
    margin-bottom: 12px;
  }

  .input-field-error-container::before {
    content: "! ";
    font-size: 19px;
  }

  .index-search-form-container .ucop-div-select.invalid .cce-search-form-control-icon {
    stroke: #ffb551;
  }

.ucop-div-select.invalid .cce-search-form-control-icon {
    stroke: red;
  }

  form .ucop-div-select.invalid select,
  .ucop-div-select.invalid input {
    color: red;
    border: 1px solid red;
  }

  .instructor-name-input-container
    input
    div[psuedo="-webkit-calendar-picker-indicator"] {
    display: none;
    visibility: hidden;
  }

  .ucop-text-input.invalid .input-field-error-container {
    display: block;
    font-family: KievitProRegular, "Helvetica Neue", Helvetic;
  }

  .ucop-text-input.invalid input {
    color: red;
  }

  .catalog-page-top-container h1,
  .catalog-page-top-container .cce-search-form select,
  .catalog-page-top-container .cce-search-form label,
  .results-page-top-container h1,
  .results-search-form-container .cce-search-form select,
  .results-search-form-container .cce-search-form label,
  .results-search-form-container
    .cce-search-form-submit-container
    .cce-advanced-search-button {
    color: #002033;
  }

/*SEARCH LANDING PAGE*/
.index-search-form-container {
    background: linear-gradient(to right, #29557d, #558eb7);
    padding: 32px;
    display: block;
    justify-content: center;
    text-align: center;
  }

  .index-search-form-container .cce-search-form .input-field-error-container {
    color: #00273f;
    background: #ffb551;
    text-align: center;
    padding: 4px 8px;
  }

  .index-search-form-container .cce-search-form .cce-search-form-select.invalid
    select {
      color: white;
      border-color: #ffb551;
  }


  .index-search-form-container {
    display: block;
    text-align: center;
  }

  .index-search-form-container h2 {
    color: #fff;
    text-align: center;
    font-family: KievitProMed;
    font-size: 34pt;
  }

  .index-search-form-container p {
    margin: auto;
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 20px;
    font-family: KievitProBook, sans-serif;
    max-width: 39em;
  }

  @media screen and (max-width: 331px) {
    .navbar-toggle {
      margin-top: -58px;
    }
  }

  @media screen and (max-width: 390px) {
    #content .hero h1 {
      white-space: nowrap;
    }
    #content .box-overlay-hero {
      width: 15em;
      top: 15px;
    }
  }

  .node-type-homepage .cce-search-form-input-container {
    text-align: left;
    position: relative;
  }

  .index-search-form-container .cce-search-form label,
  .index-search-form-container .cce-search-form select,
  .index-search-form-container .cce-search-form .cce-advanced-search-button {
    color: #fff;
    background-color: transparent;
    font-weight: normal;
    font-size: 16px;
    font-family: KievitProMed;
    margin-top: 2px;
  }
  .index-search-form-container .cce-index-search-button {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    border-radius: 8px;
    color: #000;
    font-family: KievitProMed,Arial,Helvetica,sans-serif;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 13px;
    padding: 0 32px;
    text-transform: uppercase;
    vertical-align: top;
}

  .results-page-top-container .cce-search-form label,
  .results-page-top-container .cce-search-form select {
    font-size: 16px;
    font-weight: normal;
    height: 50px;
    min-width: auto;
  }

  .results-page-top-container .cce-search-form label .label-text {
    white-space: nowrap;
  }

  .results-page-top-container .cce-search-form-select {
    width: auto;
  }

  .results-page-top-container .cce-search-form-submit-container .cce-advanced-search-button {
    color: #002033;
    background: none;
    text-decoration: underline;
    font-size: 16px;
    font-family: "KievitProMed";
    font-weight: normal;
    margin-top: 0px;
    padding-top: 0px;
  }

  .results-page-top-container .cce-search-button {
    color: #fff;
    background: #2c5578;
    width: 100%;
    margin-top: 15px;
  }

  .results-page-top-container .cce-search-form {
    width: 100%;
    display: flex;
  }

  .results-page-top-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 25px 50px 25px;
  }

  .results-page-top-container .cce-search-form-control-icon-container {
    top: 38px;
  }

  .results-page-top-container h1,
  .catalog-page-top-container h1 {
    font-size: 38pt;
    line-height: 56px;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
  }
  @media screen and (max-width: 1200px) {
    .results-page-top-container {
      align-items: baseline;
    }
    .results-page-top-container .cce-search-form {
      flex-wrap: wrap;
    }
    .results-page-top-container .cce-search-form-select {
      margin-right: 18px;
    }
  }

  .results-page-header-container {
    margin-right: 32px;
    width: min-content;
    color: #002033;
    min-width: 308px;
  }

  .results-page-form {
    display: inline-block;
    align-self: flex-end;
  }

  .results-page-top-container button.back-btn,
  .catalog-info-container button.back-btn {
    display: flex;
    color: #005581;
    background: white;
    text-decoration: underline;
    font-size: 16pt;
    font-family: "KievitProMed";
    margin-bottom: 10px;
    padding-left: 0px;
  }

  .back-btn::before {
    content: " \003C";
    display: inline;
  }

  span.no-results-message {
    display: block;
    text-align: center;
    font-size: 32px;
    padding: 0px 20px;
  }

  .catalog-page-top-container .cce-search-form input[type="submit"],
  .results-search-form-container .cce-search-form input[type="button"] {
    background: #2c5578;
    color: white;
  }

  .hero p.summary {
    line-height: 1.45em;
    font-size: 16pt;
    color: #005581;
    font-family: KievitProMed;
  }
  .hero h1 {
    font-size: 42pt;
    font-family: KievitProMed;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
    color: #333333;
    line-height: 43px;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .front .footer-note {
    margin-top: 20px;
    margin: auto;
    max-width: 91%;
    outline: 0;
  }
  .front .box-wrapper {
    display: flex;
    align-items: last baseline;
    justify-content: center;
    outline: 0;
  }
  .front .box-wrapper .box {
    padding: 20px;
    display: block;
    height: 228px;
    margin: 25px 15px 45px 15px;
    color: #333;
  }
  .hero {
    margin-left: 2em;
    display: block;
    height: 334px;
    background-image: url(../../../public/images/hero-desktop.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: auto;
  }
  .box-overlay-hero {
    display: block;
    width: 60%;
    position: relative;
    top: 55px;
    padding-left: 2em;
    outline: 0;
  }

  .front .main-container {
    padding: 0;
    width: auto;
    max-width: 100%;
  }
  .box-wrapper .box-img {
    display: block;
    padding: 20px 0;
    margin: 0 auto;
  }
  .box-wrapper a:hover,
  .box-wrapper a:focus {
    text-decoration: none;
  }

  span#catalog-header {
    color: black;
    background-color: white;
    border: 5px solid #FFB511;
    padding: 5px 10px;
    margin: 50px 0 0 0px;
    text-decoration: none;
    text-transform: uppercase;
  }

  /*Search landing page ends */

  div.form-block {
    display: table-cell;
  }

  div.form-row-block {
    display: block;
    margin-top: 5px;
    padding-top: 5px;
    clear: both;
    width: 100%;
  }

  div.form-row-bottom {
    margin-top: 29px;
  }

  div.input-label {
    font-family: "KievitProBook", Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    color: black;
  }

  /* form elements */
  #simple_search_form {
    margin: auto;
    position: relative;
    display: table;
    margin-bottom: 0px;
  }

  input,
  select {
    font-family: "KievitProBook", Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
    height: 40px;
  }

.preview-span {
    font-size: 14px;
    color: #FF6E1B;
}

.search-results {
  .search-table {
    max-width: 100%;

    .search-result-issue-tag {
      padding: 5px 10px;
      background: $green_medium;
      color: $white;
      margin-left: 10px;
      border-radius: 10px;
    }

    .results-counter {
      bottom: 0;
      text-align: left;
      margin-bottom: 10px;
      padding-bottom: 12px;
      border-bottom: 2px solid $hilight_gray;
    }

    table {
      td a {
        color: #005581;
        text-decoration: none;
      }
    }
  }
}

div.non-student {
    background: rgba(255, 255, 255, 0.5);
    padding: 25px;
}

.question-icon {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
  }
  .med_f24 {
    font-size: 24px;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
  }
  .underline {
    text-decoration: underline;
  }
  .footer-note .sub-header {
    font-size: 18px;
    display: block;
    padding-bottom: 15px;
  }
  .footer-note {
    text-align: left;
    margin-top: 20px;
  }

.select-width {
  width: 272px;
}


/*  when button has an ID, applying the style only to the class does not work :-\  */
#advanced_search {
  text-transform: uppercase;
  border: 0;
  border-radius: 0px;
  border-style: none;
  background: rgba(255, 255, 255, 0.1);
  color: #1295d8;
  font-family: "KievitProMed", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  text-decoration: none;
  width: 272px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid transparent;
}

/* day of week stylized checkboxes */
.dow-button {
    margin-right: 24px;
    padding: 0px;
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    overflow: hidden;
    float: left;
    height: 66px;
    background: -webkit-linear-gradient(#ffffff, #eeeeee);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#ffffff, #eeeeee);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#ffffff, #eeeeee);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(#ffffff, #eeeeee);
    /* Standard syntax */
  }
  .dow-button:hover {
    border: 1px solid #1295d8;
  }
  .dow-button label {
    float: left;
  }
  .dow-button label span {
    text-align: center;
    border: 1px solid transparent;
    height: 64px;
    width: 150px;
    display: table-cell !important;
    vertical-align: middle !important;
  }
  .dow-button label span:hover {
    cursor: pointer;
  }
  .dow-button label input {
    position: absolute;
    top: -200px;
  }
  .dow-button input:checked + span {
    border: 1px solid #005581;
    color: #005581;
    background-color: #ffffff;
  }

  #dow_friday {
    margin-right: 0px;
  }

  .modal-row {
    display: flex;
    margin-right: 10px;
    padding: 0px;
    float: left;
  }
  .modal-row.buttons {
    margin-right: 20px;
  }
  .modal-row span {
    height: 30px;
    display: inline;
    clear: none;
    float: left;
    margin: 0px;
    position: relative;
    top: 45px;
    font-size: 14px;
  }

  .input-width-245 {
    width: 245px;
  }

  .time-from {
    margin-right: 18px;
  }

  .time-to {
    margin-left: 18px;
    margin-right: 0px;
  }

  .course_video_icon {
    cursor: pointer;
    color: #FF6E1B;
  }

  .instructor-input {
    width: auto;
    font-size: 16px;
    font-weight: 400;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
  }

  .instructor-input .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .instructor-input a {
    text-decoration: none;
  }

  #advanced_search_terms {
    margin-left: 25px;
  }

  .semi-bold {
    font-family: "KievitProBook", Arial, Helvetica, sans-serif;
    font-weight: 600;
  }

  .no-results-message {
    display: block;
    text-align: center;
    font-size: 32px;
    margin-top: 32px;
  }

  .internal-error-container {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .internal-error {
    color: #00273f;
    background: #ffb551;
    text-align: center;
    padding: 10px 20px;
  }

  .sr_hide {
    width: 1px !important;
    height: 1px !important;
    text-indent: -9999px;
    position: absolute;
  }

  /*fix for wierd breakpoint to fix container 100% width */
  @media screen and (min-width: 1010px) {
    .front #page {
      width: auto;
      margin: auto !important;
    }
    .hero {
      background-position: right;
    }
    .catalog-info-container {
      max-width: 55em;
      display: block;
    }
    .catalog-page-top-container .cce-search-form-input-container {
      text-align: left;
      width: 33%;
    }
    .catalog-image-container {
      width: 35%;
    }
    .catalog-info-container .cce-search-form-input-container label,
    .results-search-form-container .cce-search-form-input-container label {
      font-size: 10pt;
      font-weight: 400;
    }
    .catalog-info-container .subject_area-input-container select,
    .catalog-info-container .cce-search-form-input-container select,
    .results-search-form-container .cce-search-form-input-container select,
    .results-search-form-container .subject_area-input-container select {
      font-size: 12pt !important;
    }
  }

  @media screen and (max-width: 1010px) {
    .catalog-image-container {
      display: none !important;
    }

    .catalog-info-container .cce-search-form {
      width: 30%;
      display: inline-flex;
    }

    .results-page-top-container {
        margin-top: 16px;
        display: block;
    }

    .results-page-header-container {
        margin-right: 32px;
        width: max-content;
        color: #002033;
    }
  }
  /*responsive for small desktop */
  @media (min-width: 801px) and (max-width: 1200px) {
    .hero {
      background-position: 40% 0;
    }
    .hero h1 {
      font-size: 30pt;
    }
    .hero p.summary {
      font-size: 14pt;
    }
    .box-overlay-hero {
      width: 28em;
      padding-left: 0.5em;
      top: 45px;
    }
  }

  /*responsive for ipad  & smaller*/
  @media screen and (max-width: 800px) {
    .front .results-search-form-container .cce-search-form-submit-container {
      justify-content: center;
    }
    .node-type-homepage
      .catalog-page-top-container
      .cce-search-form-input-container {
      text-align: left;
      width: 100%;
    }
    .catalog-info-container {
      width: 100%;
    }
    .front .cce-search-form-submit-container {
      margin: 20px auto !important;
      display: block;
    }
    .results-page-top-container .cce-search-form select {
      font-size: 15pt;
      font-family: KievitProBook, Arial, Helvetica, sans-serif;
      height: 50px;
    }

    .cce-search-form-submit-container {
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }

    #course_search_results #page-size {
      bottom: 6px;
    }
    #course_search_results #set-page-size::after label {
      display: none;
    }

    #advanced_search_terms {
      padding: 6px;
    }

    #course_search_results .summary {
      font-size: 14px;
      padding: 6px;
    }
    .front .cce-search-form-submit-container {
      display: flex;
      justify-content: center;
    }
    .cce-search-form input[type="submit"] {
      width: 140px;
    }
    .catalog-main-content .cce-search-form input[type="submit"] {
      width: 200px;
    }

    .box-overlay-hero {
      display: block;
      width: 23em;
      position: relative;
      top: 2em;
      padding-left: 0;
    }
    .hero {
      background-position: 40% 0;
    }
    .hero h1 {
      font-size: 26pt;
      font-family: KievitProMed;
    }
    .hero p.summary {
      line-height: 1.45em;
      font-size: 13pt;
    }
    .front .box-wrapper .box {
      font-size: 17pt;
      text-align: center;
    }
    .node-type-homepage .index-search-form-container p {
      margin: 10px 155px;
    }
    .node-type-homepage .index-search-form-container p {
      margin: auto;
      padding: 30px 0;
    }
    .front .footer-note {
      margin-top: 20px;
      outline: 0;
    }
    .front .box-wrapper {
      width: 100%;
      margin: 0 auto;
      outline: 0;
    }
    .cce-search-form-submit-container {
      margin-top: 0;
    }
    .front .footer-note {
      max-width: 90%;
      outline: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 890px) {
    .index-search-form-container {
      padding: 32px 52px 32px 32px;
    }
  }

  /*responsive for all Phones */
  @media screen and (max-width: 701px) {
    .catalog_info .social-links-list {
      display: inherit;
    }
    .catalog_info .social-links-list-mobile {
      display: inherit;
    }
  }

  /*responsive for large Phones */
  @media (min-width: 480px) and (max-width: 701px) {
    .catalog-main-content {
      padding: 30px 35px 0px 0px !important;
    }
    .catalog-info-container .subject_area-input-container select,
    .catalog-info-container .cce-search-form-input-container select {
      font-size: 10pt !important;
    }
    .catalog-info-container .cce-search-form-input-container label {
      font-size: 9pt !important;
    }
    .catalog-info-container .cce-search-form {
      width: 35% !important;
    }
    .catalog-info-container
      .cce-search-form
      .cce-search-form-submit-container
      input {
      font-size: 10pt !important;
    }
    .hero p.summary {
      line-height: 1.45em;
      font-size: 11pt;
    }
    .hero {
      background-position: -150px 0px;
    }

    .front .box-wrapper .box {
      padding: 12px;
      height: 225px;
      margin: 20px 0px 45px 0px;
      font-size: 15pt;
    }
    .results-page-header-container {
        width: max-content;
    }
    .front .cce-search-form-submit-container {
        margin: 20px auto !important;
        display: block;
    }
  }
    /* search form responsive for ipad  & smaller*/
@media screen and (max-width: 890px) {
    .logo {
        text-align: left;
    }
    .front .cce-search-form label, .front .cce-search-form .cce-advanced-search-button {
        width: 100%;
    }

    .cce-search-form-select {
        width: 100%;
    }

    .cce-search-form-select.invalid {
        height: 130px;
    }

    .cce-search-button, .index-search-form-container .cce-index-search-button {
      width: 100%;
    }

    .index-search-form-container .cce-search-form-submit-container {
      display: inline-block;
      width: 100%;
    }
    .cce-search-form-submit-container {
      text-align: center;
    }
    .results-search-form-container .cce-search-form-input-container {
      width: 100%;
      padding-left: 10%;
    }
    .results-search-form-container .cce-search-form-input-container select,
    .results-search-form-container .subject_area-input-container select {
      width: 80%;
    }
    .results-search-form-container .cce-search-form-control-icon-container {
      right: 22%;
    }
    .results-page-top-container .cce-search-form {
      display: block;
      padding-right: 40px;
    }
    .results-page-top-container .cce-search-form-select {
      width: 100%;
    }
    .cce-search-form-submit-container {
      justify-content: center;
      display: block;
    }
    .cce-search-form input[type="submit"] {
      width: 140px;
    }

    .cce-advanced-search-modal-container
      .cce-advanced-search-modal-content
      .cce-search-form-select {
        width: 300px;
        margin-right: 20px
    }

    .cce-advanced-search-modal-container .instructor-input {
        width: 300px;
    }

  }

  /*responsive for Phones */
  @media screen and (max-width: 479px) {
    .cce-search-time-to {
      position: relative;
      top: 0px;
      right: -5px;
    }

    .results-page-top-container .cce-search-form {
        padding-right: 0px;
    }
    .cce-advanced-search-modal-container .cce-advanced-search-modal-content {
      width: 100%;
    }
    .cce-advanced-search-modal-container
      .cce-advanced-search-modal-content
      .cce-search-form-select
      select {
      width: 100% !important;
    }

    .cce-advanced-search-modal-container
      .cce-advanced-search-modal-content
      .cce-search-form-select {
        width: 100%;
    }

    .cce-advanced-search-modal-container .instructor-input {
        width: 100%;
    }

    .results-search-form-container .cce-search-form-submit-container {
      display: block;
      width: 100%;
    }
    .results-search-form-container .cce-search-form-submit-container input {
      width: 100%;
    }
    .results-page-top-container {
      padding: 0 30px 50px 25px;
      display: block;
    }
    .results-search-form-container .cce-search-form-input-container {
      width: 100%;
    }
    .results-page-header-container {
        width: max-content;
    }
    .front .cce-search-form-submit-container {
        margin: 20px auto !important;
        display: block;
    }
    .results-page-top-container .cce-search-button {
      margin-top: 0px;
    }
    .catalog-info-container .subject_area-input-container select,
    .catalog-info-container .cce-search-form-input-container select {
      width: 370px !important;
    }
    .catalog-page-top-container .catalog-main-content h1 {
      font-size: 38pt;
    }
    .catalog-page-top-container .catalog-main-content {
      padding: 25px 0px 10px 0px;
    }
    .catalog-page-top-container
      .catalog-info-container
      .subject_area-input-container
      select,
    .catalog-info-container .cce-search-form-input-container select {
      font-size: 14pt !important;
    }
    .catalog-page-top-container
      .catalog-info-container
      .cce-search-form-input-container
      label {
      font-size: 12pt !important;
    }
    .catalog-page-top-container .catalog-info-container .cce-search-form {
      display: block;
    }
    .catalog-page-top-container
      .catalog-info-container
      .cce-search-form
      .cce-search-form-submit-container
      input {
      font-size: 12pt !important;
      padding: 14px;
    }
    .catalog-page-top-container .cce-search-form-input-container {
      margin-right: 5px;
    }
    .catalog-page-top-container
      .catalog-info-container
      .cce-search-form
      .cce-search-form-submit-container
      input {
      font-size: 14pt !important;
      padding: 5px;
      width: 100%;
    }

    .node-type-homepage .index-search-form-container {
      display: block;
      text-align: center;
      margin-top: 150px;
    }

    .hero {
      margin-left: 1em;
      display: block;
      height: 215px;
      background-image: url(../../../public/images/hero-mobile.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .box-overlay-hero {
      display: block;
      width: 18em;
      position: relative;
      top: 20px;
      left: 0;
    }
    .node-type-homepage .index-search-form-container p {
      font-size: 16px;
    }
    .hero h1 {
      font-size: 18pt;
      font-family: KievitProMed;
    }
    .hero p.summary {
      line-height: 1.45em;
      font-size: 16px;
    }
    .front .box-wrapper {
      display: block;
      margin: 0 auto;
    }
    .node-type-homepage .index-search-form-container h2 {
      font-size: 22pt;
    }
    .form-row {
      display: inline-block !important;
    }
    .modal-row {
      width: 100%;
    }
    .dow-button {
      margin-bottom: 20px;
    }
    .results-page-top-container .cce-search-form label,
    .results-page-top-container .cce-search-form select {
      font-size: 16px;
      font-weight: normal;
      width: 100%;
      height: auto;
    }
    .results-page-top-container button.back-btn {
      margin-top: -10px;
    }
    .main-container .back-btn {
        padding-left: 20px;
    }
  }
  @media screen and (max-width: 330px) {
    .catalog-page-top-container
      .catalog-info-container
      .cce-search-form-input-container
      label {
      font-size: 5pt !important;
    }
  }


  @media (max-width: 768px) {
    .logo {
        text-align: left;
    }

    .navbar-default .navbar-toggle {
        border-color: none;
        background: #fff;
        margin-right: inherit;
    }

    .back-btn, .back-btn.course_details_page {
      padding: 0 0 10px 40px;
      font-size: 12pt;
      font-family: "KievitProBook";
      margin-top: 5px;
    }

    .front .cce-search-form label, .front .cce-search-form .cce-advanced-search-button {
        width: 100%;
    }

    .results-page-top-container .cce-search-form select {
        width: 100%;
    }

    .results-page-top-container .cce-search-form-submit-container .cce-advanced-search-button {
      margin-top: 15px;
    }

    .cce-search-button {
      width: 100%;
      height: 50px;
      margin-top: 0px;
    }
    .cce-index-search-button {
      width: 100%;
      height: 50px;
      margin-top: 0px;
    }
    .index-search-form-container .cce-search-form-input-container {
      margin-right: 0;
    }
    .results-search-form-container .cce-search-form-input-container {
      padding-left: 0;
    }
    .results-search-form-container .cce-search-form-input-container select,
    .results-search-form-container .subject_area-input-container select {
      width: 100%;
    }
    .cce-search-form-control-icon-container,
    .results-search-form-container .cce-search-form-control-icon-container {
      right: 16px;
    }
    .cce-search-form-submit-container input[type="submit"] {
      width: 100%;
    }
    .index-search-form-container .cce-search-form-submit-container {
      display: block;
      width: 100%;
    }
    .cce-search-form-submit-container {
      text-align: center;
    }
    div.form-block {
      display: block;
    }
    .cce-notify-me-modal-close-button {
      margin-top: -30px;
    }
    .cce-notify-me-modal-title {
      padding-right: 10px;
    }
  }


