////////////////////////////////////////////////
// BRAND COLORS ////////////////////////////////
////////////////////////////////////////////////
// PRIMARY COLORS
$uc_blue: #1295d8;
$uc_gold: #ffb511;

$blue: #005581;
$light_blue: #72cdf4;
$dark_blue: #002033;

$gold: #ffd200;
$light_gold: #ffe551;

// SECONDARY COLORS
$orange: #ff6e1b;
$light_orange: #ff8f28;

$pink: #e44c9a;
$light_pink: #feb2e0;

$teal: #00778b;
$light_teal: #00a3ad;

$uc_gray: #7c737f;
$warm_gray_8: #8f8884;
$warm_gray_3: #beb6af;
$warm_gray_1: #dbd5cd;

$metallic_gold: #b4975a;
$light_gray: #fafafa;
$white: #ffffff;
$light_gray: #fafafa;
$medium_gray: #eeeeee;
$hilight_gray: #e0e0e0;
$gray_1: #616161;
$gray_2: #757575;
$gray_3: #0c0b0b;
$gray_4: #fafafa;
$gray_5: #d8d8d8;
$black: #000000;
$red: #e4463a;
$green_light: #74c573;
$green_medium: #5b9c5a;
$green_dark: #008000;