@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/media";

.ucop-table {
  width: 100%;
  display: block;
  max-width: 100vw;

  .table-summary {
    margin: 0 0 5px 0;
    text-align: left;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-family: "KievitProMed";
    align-items: baseline;
  }

  table {
    border-collapse: collapse;
    border: 1px solid #b5c9d1;
    border-left: 1px solid #b5c9d1;
    border-bottom: 1px solid #b5c9d1;
    font-size: 16px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 20px;
    font-weight: normal;
    width: 100%;
    .no-content-table {
      text-align: center;
      color: $gray_1;
      font-size: 10pt;
    }
    tr:first-child {
      border-right: 1px solid #b5c9d1;
    }
    tr th {
      padding: 11px 13px 10px;
      background-color: #dce8ec;
      border-top: 1px solid #b5c9d1;
      border-right: 1px solid #b5c9d1;
      position: relative;
      height: 40px;
      max-height: 40px;
      overflow: hidden;
      font-family: "KievitProBook", Arial, Helvetica, sans-serif;
      font-weight: normal;
      color: #333333;
      background-clip: padding-box;
      -webkit-transition: all 1s ease 0s;
      -moz-transition: all 1s ease 0s;
      -o-transition: all 1s ease 0s;
      -ms-transition: all 1s ease 0s;
      transition: all 1s ease 0s;
      .header-filter {
        font-size: 10pt;
        font-family: $font-bold;
        background: none;
        border: none;
        width: 140px;
        text-align: left;
        display: block;
        padding: 0;
        margin-bottom: 5px;

        &:focus {
          outline: none;
        }
      }
      button.btn-close-tooltip {
        background: $blue;
        color: $white;
        border: none;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        padding: 2px;
        font-size: 7pt;
        position: relative;
        top: -2px;
        cursor: pointer;
      }
      input.filter-field {
        height: 25px;
        width: 80%;
        margin-right: 5px;
        border-radius: 5px;
        border: 1px solid $hilight_gray;
      }
      span {
        margin-left: 5px;
      }
    }
    th[scope="col"] {
      font-family: $font-bold;
      padding-bottom: 10px;
      text-align: left;
    }
    td {
      padding: 11px 13px 10px;
      border-right: 1px solid #b5c9d1;
      text-align: left;
      vertical-align: top;
      font-family: $font;
      font-size: 16px;
      color: #171717;
    }
    tr:nth-child(even) {
      background-color: $light_gray;
    }
    tr:hover {
      background-color: $medium_gray;
    }
  }

  .skip-link {
    position: absolute;
    left: -99999em;

    &:focus {
      position: static;
    }
  }

  label {
    font-size: 16px;
    padding-top: 10px;
    font-weight: normal;
    color: #171717;
    font-family: "KievitProRegular";
    position: relative;
    top: -5px;
  }

  .svg-inline--fa {
    position: absolute;
    right: 6px;
    margin-top: 3px;
    cursor: pointer;
  }
}

.category-issue-list .results-counter {
  bottom: 0px;
  bottom: 0px;
  float: right;
  height: 30px;
}
.results-counter {
  text-align: left;
  color: $gray_1;
  font-size: 10pt;
  margin-bottom: 10px;
  clear: both;
}

#set-page-size {
  text-align: right;
  float: right;
  height: 2.5em;
  position: relative;
}

#set-page-size::after {
  content: url("../../../../public/images/icons/search/arrows-up-down.png");
  position: relative;
  right: 15px;
  top: 0px;
  z-index: 0;
}

#page-size {
  width: 60px;
  clear: none;
  margin: 0 0 10px 10px;
  height: 40px;
  appearance: none;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: transparent;
  padding: 4px 0px 3px 20px;
  z-index: 1;
  display: inline-block;
  position: relative;
}

@media (min-width: #{$break-small}) and (max-width: #{$break-large - 1px}) {
  .results-counter {
    text-align: right;
    color: $gray_2;
    font-size: 10pt;
    position: relative;
    top: 20px;
    margin-bottom: 35px;
  }
  .category-issue-list .results-counter {
    top: -10px;
    margin-bottom: 0;
  }
  .ucop-table {
    padding: 0px 25px 0px 25px;
    table,
    table thead,
    table tbody,
    table th,
    table td.mobile_transform,
    table tr {
      display: block;
      margin: auto;
    }

    table {
      clear: both;
      border: none
    }

    table tbody td,
    table tbody th.row_header {
      border-right: none;
    }

    table tr th{
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    table tr:first-child {
      display: none;
    }

    table tr:first-child::before {
      display: table-cell;
    }

    table tr {
      border: 1px solid #b5c9d1;
    }

    table tr {
      padding: 12px;
    }

    table td,
    table th.row_header {
      text-align: left;
      display: table-row;
      width: 100%;
      font-size: 16px;
      line-height: 30px;
    }

	table td::before {
      content: attr(data-label);
      font-weight: normal;
      font-family: KievitProRegular, Arial, Helvetica, sans-serif;
      color: #005581;
      padding-right: 20px;
      display: table-cell;
      overflow: auto;
      width: 140px;
	}

	table tr.back_to_top td::before,
    table tr.view_details td::before {
        width: min-content;
      }

    span.mobile_text {
        position: relative;
    }

    span.mobile_text.back_to_top{
      position: relative;
      color: #005581;
    }

    .back_to_top,
    .back_to_top tr {
      padding: 0px;
    }

    .back_to_top td {
      display: flex;
      text-align: center;
      padding: 11px 13px 10px;
    }

    a.back_to_top_a,
    a.view_details_a {
      display: block;
      width: 100%;
      height: 100%;
    }

      a.back_to_top_a span.mobile_text::before {
        content: url("../../../../public/images/icons/search/backtop-icon.png");
        position: absolute;
        left: -18px;
        top: -2px;
      }

      a.view_details_a span.mobile_text::before {
        content: url("../../../../public/images/icons/search/view-details-icon.png");
        position: absolute;
        left: -26px;
        top: -1px;
      }

      .view_details,
      .view_details tr {
        padding: 0px;
      }

      .view_details td {
        background-color: #005581 !important;
        display: flex;
        text-align: center;
        padding: 11px 13px 10px;
      }

      a.view_details_a:link,
      a.view_details_a:visited,
      a.view_details_a:hover,
      a.view_details_a:active {
        color: #ffffff !important;
      }

      a.view_details_a:hover,
      a.view_details_a:active {
        text-decoration: underline;
      }

      .table-summary {
        margin: 0 0 5px 0;
        padding: 6px;
        font-size: 16px;
      }

    label {
      display: none;
    }
  }
}
@media (max-width: #{$break-medium-small}) {
    #set-page-size:after {
        right: 15px;
    }

    .ucop-table table td, .ucop-table table th.row_header, .ucop-div-select .summer-checkbox span {
        font-size: 15px;
        font-weight: 500;
        line-height:18px;
        font-family: 'KievitProBook';
    }
    .ucop-div-select .summer-checkbox span{
        position: relative;
        top: 2px;
    }
    .ucop-table table td:before {
        padding: 5px 5px;
    }
}