@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

p {
  font-family: "KievitProBook", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  display: block;
  clear: both;
}
p.kievit_pro_med {
  font-family: "KievitProMed", Arial, Helvetica, sans-serif;
}
p.kievit_pro_med.covid {
  font-weight: bold;
  color: red;
}

/*CATALOG PAGE*/
.catalog-page-top-container {
    display: flex;
    padding: 0px 0 25px 25px;
  }

  .catalog-info-container p {
    font-size: 16pt;
    font-family: KievitProBook;
    margin: 0;
    line-height: 30px;
    color: #005581;
    padding: 0px 50px 0 0px;
  }

  .catalog-info-container p:last-of-type {
    font-family: "KievitProBold";
    font-style: italic;
  }

  .catalog-main-content {
    position: absolute;
    margin-top: -80px;
    padding: 0px 10px 30px 30px;
  }

  .catalog-main-content h1 {
    font-size: 46pt;
  }

  .catalog-info-container .cce-search-form-submit-container input {
    font-size: 14pt;
    height: 50px;
  }

  .catalog-info-container .cce-catalog-form-input-container label {
    font-size: 12pt;
  }

  .catalog-info-container .subject_area-input-container select,
  .catalog-info-container .cce-catalog-form-input-container select {
    font-size: 15pt;
    width: 295px;
    height: 50px;
  }

  .catalog-page-top-container h1,
  .catalog-page-top-container .cce-search-form select,
  .catalog-page-top-container .cce-search-form label {
    color: #002033;
  }

  .cce-search-form-submit-container {
    display: inline-block;
    margin-top: 12px;
    vertical-align: top;
    width: min-content;
  }

  .cce-catalog-form-input-container select {
    min-width: 250px;
  }

  @media screen and (max-width: 331px) {
    .navbar-toggle {
      margin-top: -58px;
    }
  }

  .node-type-homepage .cce-catalog-form-input-container {
    text-align: left;
    position: relative;
  }
  .front .cce-search-form label {
    background-color: transparent;
    font-weight: normal;
    font-family: kievitproMed;
    margin-top: 2px;
  }

  .catalog-page-top-container h1 {
    font-size: 46pt;
    line-height: 56px;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
  }

  .catalog-info-container button.back-btn {
    display: flex;
    color: #005581;
    background: white;
    text-decoration: underline;
    font-size: 16pt;
    font-family: "KievitProMed";
    margin: 0px;
    padding: 0px;
  }

  button.back-btn::before {
    content: " \003C";
    display: inline;
  }

  span.no-results-message {
    display: block;
    text-align: center;
    font-size: 32px;
    padding: 0px 20px;
  }

  .catalog-page-top-container .cce-search-form input[type="submit"]{
    background: #2c5578;
    color: white;
  }


  .front .footer-note {
    margin-top: 20px;
    margin: auto;
    max-width: 91%;
    outline: 0;
  }
  .front .box-wrapper {
    display: flex;
    align-items: last baseline;
    justify-content: center;
    outline: 0;
  }
  .front .box-wrapper .box {
    padding: 20px;
    display: block;
    height: 228px;
    margin: 25px 15px 45px 15px;
    color: #333;
  }

  .front .main-container {
    padding: 0;
    width: auto;
    max-width: 100%;
  }
  .box-wrapper .box-img {
    display: block;
    padding: 20px 0;
    margin: 0 auto;
  }
  .box-wrapper a:hover,
  .box-wrapper a:focus {
    text-decoration: none;
  }

  span#catalog-header {
    color: black;
    background-color: white;
    border: 5px solid #FFB511;
    padding: 5px 10px;
    margin: 50px 0 0 0px;
    text-decoration: none;
    text-transform: uppercase;
  }

  .catalog-info-container .subject_area-input-container select,
  .catalog-info-container .cce-catalog-form-input-container select,
  .cce-catalog-form-input-container select {
    font-size: 15pt;
    width: 295px;
    height: 50px;
    color: #002033;
}

  div.form-block {
    display: table-cell;
  }

  div.form-row-block {
    display: block;
    margin-top: 5px;
    padding-top: 5px;
    clear: both;
    width: 100%;
  }

  div.form-row-bottom {
    margin-top: 29px;
  }

  div.input-label {
    font-family: "KievitProBook", Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    color: black;
  }

  /* form elements */
  #simple_search_form {
    margin: auto;
    position: relative;
    display: table;
    margin-bottom: 0px;
  }

  input,
  select {
    font-family: "KievitProBook", Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
    height: 40px;
  }

.preview-span {
    font-size: 14px;
    color: #FF6E1B;
}

.search-results {
  .search-table {
    max-width: 100%;
    padding: 0 25px;
    .search-result-issue-tag {
      padding: 5px 10px;
      background: $green_medium;
      color: $white;
      margin-left: 10px;
      border-radius: 10px;
    }

    .results-counter {
      bottom: 0;
      text-align: left;
      margin-bottom: 10px;
      padding-bottom: 12px;
      border-bottom: 2px solid $hilight_gray;
    }

    table {
      td a {
        color: #005581;
        text-decoration: none;
      }
    }
  }
}

 .cce-catalog-form-control-icon-container {
    position: absolute;
    pointer-events: none;
    top: 38px;
    right: 16px;
  }

  .course_video_icon {
    cursor: pointer;
    color: #FF6E1B;
  }

  .cce-search-button {
    text-transform: uppercase;
    padding: 0 32px;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
    vertical-align: top;
    font-size: 16px;
    height: 50px;
    border-radius: 8px;
    appearance: none;
    background: #2c5578;
    color: white;
 }

  /*fix for wierd breakpoint to fix container 100% width */
  @media screen and (min-width: 1010px) {
    .front #page {
      width: auto;
      margin: auto !important;
    }
    .catalog-info-container {
      max-width: 55em;
      display: block;
    }
    .catalog-page-top-container .cce-catalog-form-input-container {
      text-align: left;
      width: 330px;
    }

    .catalog-image-container {
      width: 35%;
    }
    .catalog-info-container .cce-catalog-form-input-container label,
    .catalog-info-container .subject_area-input-container select,
    .catalog-info-container .cce-catalog-form-input-container select
    {
      font-size: 16px !important;
      font-weight: 400;
      width: 100%;
    }
  }

  @media screen and (max-width: 1010px) {
    .catalog-image-container {
      display: none !important;
    }
    .catalog-info-container .cce-search-form {
      width: 30%;
      display: inline-flex;
    }
  }

  /*responsive for small desktop */
  @media (min-width: 801px) and (max-width: 1300px) {
    .catalog-main-content {
      margin-top: 0px;
      position: relative;
    }
  }

  @media (min-width: 801px) and (max-width: 1200px) {
    .catalog-image-container img {
      min-width: 30em;
    }
    .catalog-page-top-container {
      padding: 25px 0 25px 25px;
    }

  }

  /*responsive for ipad  & smaller*/
  @media screen and (max-width: 800px) {
    .node-type-homepage
      .catalog-page-top-container
      .cce-catalog-form-input-container {
      text-align: left;
      width: 100%;
    }
    .catalog-info-container {
      width: 100%;
    }
    .front .cce-search-form-submit-container {
      margin: 20px auto !important;
      display: block;
    }
    .results-page-top-container .cce-search-form select {
      color: #002033;
      font-size: 15pt;
      font-family: KievitProBook, Arial, Helvetica, sans-serif;
      height: 50px;
    }

    .cce-search-form-submit-container {
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }

    #course_search_results #page-size {
      bottom: 6px;
    }
    #course_search_results #set-page-size label {
      display: none;
    }

    #advanced_search_terms {
      padding: 6px;
    }

    #course_search_results .summary {
      font-size: 14px;
      padding: 6px;
    }
    .front .cce-search-form-submit-container {
      display: flex;
      justify-content: center;
    }
    .cce-search-form input[type="submit"] {
      width: 140px;
    }
    .catalog-main-content .cce-search-form input[type="submit"] {
      width: 200px;
    }

    .front .box-wrapper .box {
      font-size: 17pt;
      text-align: center;
    }
    .node-type-homepage .index-search-form-container p {
      margin: 10px 155px;
    }
    .node-type-homepage .index-search-form-container p {
      margin: auto;
      padding: 30px 0;
    }
    .front .footer-note {
      margin-top: 20px;
      outline: 0;
    }
    .front .box-wrapper {
      width: 100%;
      margin: 0 auto;
      outline: 0;
    }
    .front .footer-note {
      max-width: 90%;
      outline: 0;
    }
  }

  /*responsive for all Phones */
  @media screen and (max-width: 701px) {
    .catalog_info .social-links-list {
      display: inherit;
    }
    .catalog_info .social-links-list-mobile {
      display: inherit;
    }
  }

  /*responsive for large Phones */
  @media (min-width: 480px) and (max-width: 701px) {
    .catalog-info-container .subject_area-input-container select,
    .catalog-info-container .cce-catalog-form-input-container select {
      font-size: 10pt !important;
    }
    .catalog-info-container .cce-catalog-form-input-container label {
      font-size: 9pt !important;
    }
    .catalog-info-container .cce-search-form {
      width: 100% !important;
    }
    .catalog-info-container
      .cce-search-form
      .cce-search-form-submit-container
      input {
      font-size: 10pt !important;
    }

    .front .box-wrapper .box {
      padding: 12px;
      height: 225px;
      margin: 20px 0px 45px 0px;
      font-size: 15pt;
    }
    .results-page-header-container {
        width: max-content;
    }
    .front .cce-search-form-submit-container {
        margin: 20px auto !important;
        display: block;
    }
  }

  /*responsive for Phones */
  @media screen and (max-width: 479px) {
    .cce-search-time-to {
      position: relative;
      top: 0px;
      right: -5px;
    }

    .cce-advanced-search-modal-container
      .cce-advanced-search-modal-content
      .cce-search-form-select
      select {
      width: 100% !important;
    }

    .instructor-input {
        width: 100%;
    }

    .results-page-header-container {
        width: max-content;
    }
    .front .cce-search-form-submit-container {
        margin: 20px auto !important;
        display: block;
    }
    .results-page-top-container {
      margin-top: 0px;
    }
    .results-page-top-container .cce-search-button {
      margin-top: 0px;
    }
    .catalog-page-top-container {
      padding: 0 25px 25px;
    }
    .catalog-info-container .subject_area-input-container select,
    .catalog-info-container .cce-catalog-form-input-container select {
      width: 370px !important;
    }
    .catalog-page-top-container .catalog-main-content h1 {
      font-size: 38pt;
      margin-top: 0px;
    }
    .catalog-page-top-container .catalog-main-content {
      padding: 25px 0px 10px 0px;
    }
    .catalog-page-top-container
      .catalog-info-container
      .subject_area-input-container
      select,
    .catalog-info-container .cce-catalog-form-input-container select {
      font-size: 14pt !important;
    }
    .catalog-page-top-container
      .catalog-info-container
      .cce-catalog-form-input-container
      label {
      font-size: 12pt !important;
    }
    .catalog-page-top-container .catalog-info-container .cce-search-form {
      display: block;
    }
    .catalog-page-top-container
      .catalog-info-container
      .cce-search-form
      .cce-search-form-submit-container
      input {
      font-size: 12pt !important;
      padding: 14px;
    }
    .catalog-page-top-container .cce-catalog-form-input-container {
      margin-right: 5px;
    }
    .catalog-page-top-container
      .catalog-info-container
      .cce-search-form
      .cce-search-form-submit-container
      input {
      font-size: 14pt !important;
      padding: 5px;
      width: 100%;
    }

    .node-type-homepage .index-search-form-container {
      display: block;
      text-align: center;
      margin-top: 150px;
    }

    .node-type-homepage .index-search-form-container p {
      font-size: 16px;
    }
    .front .box-wrapper {
      display: block;
      margin: 0 auto;
    }
    .node-type-homepage .index-search-form-container h2 {
      font-size: 22pt;
    }
    .form-row {
      display: inline-block !important;
    }
    .modal-row {
      width: 100%;
      display: block;
    }
    .modal-row span {
        top: 15px;
    }
    .time-to {
        margin-left: 0;
        top: -20px;
    }
    .dow-button {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 330px) {
    .catalog-page-top-container
      .catalog-info-container
      .cce-catalog-form-input-container
      label {
      font-size: 5pt !important;
    }
  }

  /* search form responsive for ipad  & smaller*/
@media screen and (max-width: 890px) {
  .logo {
      text-align: left;
  }
  .cce-search-button {
    width: 100%;
  }

  .catalog-page-top-container {
    display: flex;
    padding: 0px 45px 25px 25px;
  }

  .catalog-main-content {
    margin-top: 0px;
    position: relative;
    padding: 0px;
  }

  .catalog-info-container .cce-search-form {
    display: block;
    margin-top: 20px;
    width: 100%;
  }
  .cce-search-form-submit-container {
    text-align: center;
  }
  .catalog-info-container
  .cce-catalog-form-input-container select,
  .catalog-info-container .subject_area-input-container select,
  .cce-catalog-form-input-container select {
    width: 100%;
  }
  .cce-search-form input[type="submit"] {
    width: 140px;
  }
  .cce-catalog-form-control-icon-container {
    right: 15px;
    top: 38px;
    width: 100%;
    text-align: right;
  }

  .search-results .search-table {
    max-width: 100%;
    padding: 0px 45px 25px 25px;
  }
}

@media (min-width: 702px) and (max-width: 1024px) {
  .catalog-info-container .cce-search-form {
      display: inline-flex;
      width: 100%
  }
  .catalog-main-content {
    position: relative;
    margin-top: 0px;
    padding: 0px;
  }
  .catalog-main-content .ucop-div-select {
    margin-right: 10px
  }
  .catalog-main-content .cce-search-form-submit-container {
    width: min-content;
    margin-top: 0px;
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .logo {
      text-align: left;
  }
  .cce-search-button {
    width: 100%;
    height: 50px;
    margin-top: 0px;
  }
  .index-search-form-container .cce-catalog-form-input-container {
    margin-right: 0;
  }
  .cce-search-form-submit-container input[type="submit"] {
    width: 100%;
  }
  .index-search-form-container .cce-search-form-submit-container {
    display: block;
    width: 100%;
  }
  .cce-search-form-submit-container {
    text-align: center;
  }
  div.form-block {
      display: block;
    }
  .cce-notify-me-modal-close-button {
    margin-top: -30px;
  }
  .cce-notify-me-modal-title {
    padding-right: 10px;
  }
  .search-results .search-table {
    max-width: 100vw;
    padding: 0px 15px;
  }
  .catalog-main-content .cce-search-form-submit-container {
    width: min-content;
    margin-top: 25px;
  }
}

