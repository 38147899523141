@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

/* Drupal style for alert banner */
.alert-container {
    display: block;
    width: 100%;
    padding: 10px;
    background: #A30000;
    border: 1px solid #b5c9d1;
    color: $white;
}

.close {
    background: white;
    border: 0;
    position: absolute;
    right: 5px;
    top: 10px;
    width: 32px;
    height: 32px;
    opacity: 1;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    &:before,
    &:after {
      position: absolute;
      top: 0px;
      left: 15px;
      content: " ";
      height: 20px;
      width: 2px;
      background-color: $white;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }