@import "../../../styles/fonts";
@import "../../../styles/widget-colors";

.ucop-div-select {
  display: inline-block;
  margin-right: 18px;
  vertical-align: top;
  text-align: left;
  position: relative;
}

.ucop-select {
  display: block;
  margin: 15px 0 5px 0;
  text-align: left !important;
  color: $text_input_label;
  font-weight: 400;

  select {
    appearance: none;
    height: 40px;
    border: 1px solid #808d98;
    border-radius: 8px;
    background: transparent;
    background-color: transparent;
    padding: 10px 32px 10px 10px;
    color: #fff;
    margin-top: 0px;
    font-family: "KievitProMed,", Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 20px;

    &:disabled {
      color: $text_input_fg_disabled;
      background-color: $text_input_bg_disabled;
    }
  }
  select option {
    color: #171717;
    font-family: "KievitProRegular", Arial, Helvetica, sans-serif;
  }
}

.ucop-select .label-text {
  font-family: "KievitProMed", Arial, Helvetica, sans-serif;
}

.ucop-div-icon {
  position: absolute;
}

@media screen and (max-width: 890px) {
  .ucop-div-select {
    width: 100%;
    margin-right: 0px;
  }
}

@media screen and (max-width: 768px) {
  .ucop-div-select {
    width: 100%;
    margin-right: 0px;
  }
}
