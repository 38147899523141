@import "../../styles/colors";
@import "../../styles/media";

.page-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: auto;
  @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
    flex-wrap: wrap;
  }
  @media (min-width: #{$break-medium}) and (max-width: #{$break-large - 1px}) {
    flex-wrap: nowrap;
  }
}

.page-content-children {
  p {
    line-height: 20pt;
    padding: 5px;
  }
  @media (min-width: #{$break-small}) and (max-width: #{$break-large - 1px}) {
    display: grid;
    padding: 0;
  }
}

.main-bg {
  background-image: none !important;
  background-repeat: no-repeat;
  margin: auto !important;
}

.main-container {
    text-align: left;
    &.search-modal-open {
      min-height: 920px;
    }
  }

.cce-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

div.form-row {
  display: flex;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  clear: both;
  width: 100%;
}

