@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.cce-summer-courses {
    padding: 0 25px 0px 25px;
}

.cce-summer-form {
    position: absolute;
    top: 10px;
    margin-left: 350px;
    display: inline-flex;
}

.cce-summer-form select {
    appearance: none;
    height: 40px;
    border: 1px solid #005581;
    border-radius: 8px;
    background: transparent;
    padding: 10px 32px 10px 10px;
    margin-top: 5px;
    min-width: 200px;
    color: #171717;
    font-family: "KievitProRegular", Arial, Helvetica, sans-serif;
    display: flex;
}

.cce-summer-form .cce-search-form-control-icon {
    stroke: none;
}

.cce-summer-form-select-icon {
    position: absolute;
    pointer-events: none;
    right: 10px;
    top: 13px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4 8L12 16L20 8' stroke='%23005581' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
    stroke: none;
}


.cce-summer-form-select {
    position: relative;
    z-index: 1;
    width: 320px;
}

.cce-summer-form .ucop-select {
    display: inline-flex;
    margin: 0px;
}

.summer-table {
    margin-top: 20px;
}

.summer-table #page-size {
    margin-top: -5px;
    padding: 4px 0px 3px 15px;
    position: relative;
}

.summer-table .table-summary {
    font-size: 16px;
}

.summer-table table td:before {
    width: 180px;
}

.summer-table #set-page-size:after {
    top: -2px;
    z-index: 0;
}

.summer-table table td a{
    color:#005581;
}

.cce-summer-form-select .label-text{
    position: relative;
    top: 15px;
    margin-right: 5px;
    width: 210px;
}

.cce-summer-form [type="checkbox"]{
    vertical-align:middle;
    width: 18px;
    margin-right: 5px;
}

.cce-summer-form .summer-checkbox {
    color: #171717;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.cce-summer-form .summer-checkbox label {
    display: inline-flex;
}

.summer-checkbox span {
    margin-top: 14px;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .cce-summer-form {
        top: 0px;
        display: inline-flex;
        margin-left: 25px;
        position: relative;
    }
    .summer-table {
        margin-top: 0px;
    }
}

/*responsive for Phones */
@media screen and (max-width: 767px) {
    .cce-summer-courses {
        padding: 0px;
    }
    .cce-summer-form {
        display: block;
        position: relative;
        margin: 0px;
        padding: 10px 15px 10px 15px;
    }
    .cce-summer-form-select{
        width: 100%;
    }
    .cce-summer-form .ucop-select {
        width: 100%;
        display: block;
    }
    .cce-summer-form select {
        width: 100%;
    }
    .cce-summer-form-select .label-text{
        top: 0px;
    }
    .cce-summer-form-select-icon {
        right: 10px;
        top: 35px;
    }
    .summer-table {
        padding: 0 15px 0 15px;
    }
    .summer-table table td a{
        line-height:20px;
        color:#005581;
    }
}
