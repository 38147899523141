@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/media";

.pagination {
  //border-top: 1px solid $gray_5;
  width: 100%;
  display: flex !important;
  justify-content: flex-end;
  margin-top: 15px;
  button {
    cursor: pointer;
    color: $black;
    float: left;
    padding: 6px 7px;
    margin: 2px 1px;
    text-decoration: none;
    font-family: $font;
    font-size: 10pt;
    border: solid 1px #b5c9d1;
    background: transparent;
    &:hover:not(.active) {
      background-color: $gray_5;
      background: transparent;
    }
    border-radius: 15px;
    color: #005581;
  }
  button.active {
    cursor: auto;
    background-color: $blue;
    color: $white;
  }
  span {
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
  }
  div {
    display: flex;
  }
}

.pagination div:last-child {
    margin-left: auto;
    justify-content: right;
}

@media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
  .pagination .right {
    width: max-content;
    flex-wrap: wrap;
  }

  .pagination .left {
    display: block;
    button {
      width: max-content;
    }
    margin-right: 5px;
  }
}
