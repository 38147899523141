@import "../../styles/colors";
@import "../../styles/fonts";

.app-footer {
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding: 20px 25px 20px 25px;
  border-top: 1px solid $light_gray;
  background: $white;
  font-size: 11pt;
  font-family: $font-book;
}

.region-footer{
  text-align: left;
  margin: 35px 0px;
}

@media screen and (min-width: 1200px){
  .footer.container {
    width: 93%;
  }
}
@media screen and (max-width: 890px) {
  .region-footer{
    padding : 0px 10px;
  }
}

@media screen and (max-width: 768px) {
  .region-footer{
    padding : 0px 10px;
  }
}
