@import "../../../styles/colors";
@import "../../../styles/fonts";

.ucop-success-banner {
  border: 2px solid $green_light;
  height: auto;
  min-height: 40px;
  width: 51%;
  transform: translate(-50%, -50%);
  position: relative;
  left: 50%;
  top: 2.5em;
  margin-bottom: 2em;
  border-radius: 10px;
  color: $green_dark;
  font-size: 11pt;
  padding: 10px;
  text-align: center;
  font-family: $font-book;
  min-width: 330px;
  line-height: 150%;
}
