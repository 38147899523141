body {
  font-family: "KievitProRegular", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  margin: auto;
  color: #171717;
  background: #ffffff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left top;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
