@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/media";
.tooltip {
  position: relative;
  display: inline-block;
  background: $white;
  color: $gray_2;
  width: 12px;
  height: 12px;
  font-size: 7pt;
  border-radius: 10px;
  text-align: center;
  margin-left: 2px;
  padding: 1px 5px;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $gray_2;
  color: $white;
  text-align: center;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  line-height: 15px;
}

.tooltip:hover .tooltiptext,
.tooltip:focus .tooltiptext {
  visibility: visible;
}
