@import "../../../styles/fonts";
@import "../../../styles/colors";

.ucop-button {
  margin: 15px 0;
  padding: 11px;
  height: 40px;

  font-size: 14px;
  font-family: $font;

  border: none;
  border-radius: 0px;
  border-style: none;

  background: #005581;
  color: #ffffff;

  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}
