@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/media";


.back-btn.course_details_page {
  padding: 0 0 10px 50px;
}

#course_view #course_info h2 {
    color: #002033;
    margin-top: 0px;
}

p {
  font-family: "KievitProBook", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  display: block;
  clear: both;
  margin: 20px 0;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: KievitProMed;
  font-size: inherit;
  line-height: 25px;
  font-weight: normal;
}

#course_view {
  margin: 0 50px;
}

div#catalog_course_info {
  display: flex;
}

div.catalog_info_button {
  width: auto !important;
  margin-top: 14px;
  display: flex !important;
}

div.catalog_info_button a#notify_me,
div.catalog_info_button a.catalog_info_button {
  font-family: "KievitProBold", Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 10px;
}

span#catalog_header {
  color: black;
  background-color: white;
  border: 5px solid #FFB511;
  padding: 5px 10px;
  margin: 50px 0 0 0px;
  text-decoration: none;
  text-transform: uppercase;
}

div.catalog_info_button a#notify_me {
  background-color: #feb551;
  color: black;
  border: 1px solid #ffffff;
}

div.catalog_info_button a.course_info_button {
  background-color: #005581;
  color: white;
}

div#video_plus_text_container {
  display: flex;
  flex-direction: column;
  min-width: 583px;
  max-width: 675px;
  width: 100%;
  padding-top: 20px;
  padding-right: 40px;
}

div#catalog_credit_description {
  flex-direction: column;
  margin-left: 30px;
  width: auto;
}

div#catalog_video {
  width: 100%;
}

#enrollment_info {
  a {
    color: #005581;
    text-decoration: underline;
  }
}


@media screen and (min-width: 1010px) {
  #course_description {
    float: left;
    /* width: 675px; */
    width: 54%;
    padding-right: 40px;
  }

  #catalog_key_information {
    float: left;
    width: 100%;
    padding-left: 0px;
  }

  div#course_info {
    display: block;
    margin: 0px;
    padding: 0px;
    overflow: auto;
}
}

#catalog_course_view .long_description, #catalog_key_information p, #catalog_key_information .span {
  font-family: "KievitProBook", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 25px;
}

p.long_description {
  margin-top: 20px;
}

.course_video {
  overflow: auto;
  padding: 0px;
  background: #005581;
}

.video_wrapper, #previewVideoForm #iframe-content {
  position: relative;
  margin: 0 auto;
  padding: 0px;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video_wrapper iframe, .video-wrapper object, #previewVideoForm #iframe-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border: 5px solid #005581;
  border-right: 6px solid #005581;
  border-bottom: 6px solid #005581;
}

.social_links_list {
  margin: 0;
  text-align: left;
  font-size: 14px;
  font-family: KievitProRegular, "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0px;
  padding-top: 20px;
}

.social_links_list li {
  display: inline-block;
  margin-right: 5px;
}

.social_links_list .share_text {
  position: relative;
  top: 0px;
  margin-left: 4px;
  font-family: "KievitProRegular";
  font-size: 14px;
}

.social_links_list button {
    padding: 0px;
    border: none;
    background-color: transparent;
}

.academic_calendar_container {
  padding-top: 10px;
}

.academic_calendar {
  color: #005581;
  text-decoration: underline;
  font-family: "KievitProBook", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.pdf_icon {
  position: relative;
}

.pdf_icon::after {
  content: url(../../../../public/images/icons/search/pdf_icon_sm.png);
  top: 0px;
  padding-left: 6px;
}

.offscreen {
  position: absolute !important;
  left: -999em;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

div.info_buttons {
  padding: 0px;
  padding-bottom: 0px;
  display: flex;
  margin-top: 14px;
  margin-bottom: 10px;
}

#course_view a.course_info_button {
  align-items: center;
  justify-content: center;
  display: flex;
  background: #005581;
  color: white;
  border-radius: 10px;
  margin-right: 10px;
  padding: 10px 15px;
  text-transform: uppercase;
  min-width: 185px;
  max-width: 300px;
  font-family: "KievitProBold", Arial, Helvetica, sans-serif;
  font-weight: bold;
  float: left;
  text-decoration: none;
}

div.x-campus_info {
  min-height: 390px;
  margin-top: 50px;
  font-size: 16px;
  line-height: 25px;
}

div.x-campus_info_catalog {
    border: none;
    min-height: auto;
    padding: 0px;
}

div.x-campus_info_pic {
  float: right;
  height: 350px;
  background-color: #2a6496;
  margin-left: 20px;
}

div.x-campus_info_pic img {
  object-fit: cover;
  object-position: right;
  width: 100%;
  height: 100%;
  max-width: 530px;
  max-height: 360px;
}

#course_view h3 {
  font-size: 24px;
  text-decoration: none;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-family: "KievitProBold";
}

#course_view h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "KievitProBold";
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 5px;
}

.course_credit_description {
  font-weight: 400;
}

.course_approval_content {
  font-family: "KievitProBook", Arial, Helvetica, sans-serif;
  font-size: 16px;
}

#show_other_approvals {
  background: none;
  border: none;
  text-align: left;
  white-space: nowrap;
  font-size: 18px;
  font-style: italic;
  font-family: "KievitProMed";
  color: #005581;
  text-decoration: underline;
  padding: 0px;
}

#show_other_approvals:hover {
  text-decoration: underline;
}

div#all_sections ul li span {
  margin-left: 10px;
}

#list_all_sections{
  background: none;
  border: none;
  text-align: left;
  white-space: nowrap;
  font-size: 16px;
  font-family: "KievitProMed";
  color: #005581;
  text-decoration: underline;
  padding: 0px;
}

div.course_creator_info {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  font-family: "KievitProBook", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

div.faculty_photo img {
  margin: 15px 20px 20px 0px;
  vertical-align: top;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  object-fit: cover;
  border: 1px solid #cccccc;
}

.visible_print {
  display: none;
}

div.course_instructor_info {
  display: inline-block;
  a {
    color: #005581;
    text-decoration: underline;
  }
}

#faculty_info .course_instructors strong {
  font-family: "KievitProBold", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
}

#additional_info a {
  color: #005581;
  text-decoration: underline;
}

#additional_info strong {
  font-family: "KievitProBold", Arial, Helvetica, sans-serif;
  font-weight: bold;
}

#course_view div.course_instructor_info p {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 20px;
}

#course_view div.course_instructor_info p strong {
  margin-right: 10px;
}

h3#addl_crs_info {
  margin-top: 15px;
  margin-bottom: 25px;
}

@media (max-width: 1024px) {
  #course_view a.course_info_button {
    min-width: 120px;
  }
}

@media (max-width: 1009px) {

}

@media screen and (min-device-width: 300px) and (max-width: 1009px){
  div#catalog_course_info {
    display: initial;
  }

  .mobile_hide {
    display: none;
  }
}

/* course view responsive for ipad  & smaller*/
@media screen and (max-width: 800px) {
  .back-btn.course_details_page {
    padding: 0 0 5px 37px;
    margin-bottom: 0px;
    font-size: 12pt;
    font-family: "KievitProBook";
  }
  #course_view {
    margin-right: 37px;
    margin-left: 37px;
  }
  #course_view #course_info h2 {
    color: #002033;
    font-size: 15pt;
    line-height: 100%;
  }

  #other_approvals {
    line-height: 1;
  }

  .mobile_small_text, .mobile_small_text p, .mobile_small_text strong {
    font-family: "KievitProBook" !important;
    font-size: 16px !important;
    line-height: 140% !important;
  }
  #course_info #catalog_key_information .social-links-list {
    padding-top: 0px;
  }
  #course_info #catalog_key_information .social-links-list img {
    height: 12pt;
    font-size: 9pt;
  }
  #course_info #catalog_key_information .social-links-list li {
    margin-right: 0px;
  }
  #course_info .academic-calendar-container {
    padding-top: 4px;
  }
  #course_info p.credit-info {
    margin-top: 6px;
  }
  #course_info #catalog_key_information .info-buttons-container {
    display: flex;
    justify-content: space-between;
  }
  div.info_buttons {
    display: flex;
  }
  a.course_info_button,
  div.course_info_message {
    min-width: 90px !important;
    margin-bottom: 3px !important;
    margin-right: 5px !important;
    padding: 10px 5px;
    font-weight: normal;
    text-decoration: none;
  }

  span#catalog-header {
    color: black;
    background-color: white;
    border: 5px solid #FFB511;
    padding: 5px 10px;
    margin: 50px 0 0 0px;
    font-size: 20px;
  }

  div.x-campus_info {
    margin-top: 10px !important;
    min-height: 0 !important;
  }

  #course_view h1{
    margin-bottom: 10px;
  }

  #course_view h3{
    font-size: 14pt !important;
    margin-bottom: 0px;
  }

  #course_view #additional_info h4 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  #course_view #additional_info p {
    margin-bottom: 10px;
    margin-top: 0px;
  }
  #course_view div.course_creator_info,
  #course_view div.course_creator_info div,
  #course_view div#faculty_info {
    margin-bottom: 0px;
  }
  #course_view div.course_instructors {
    margin-top: 10px;
  }
  #course_view .course_creators .faculty_photo img {
    min-width: 125px;
    max-width: 125px;
    height: 125px;
  }
  #course_view div#faculty_info {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  div.catalog_info_button {
    width: 100% !important;
  }

  div.catalog_info_button a#notify_me,
  div.catalog_info_button a.course_info_button {
    font-family: "KievitProBold", Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin: 10px 0 3px 0;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 10px;
 }

  div.catalog_info_button a#notify_me {
    background-color: #feb551;
    color: black;
    min-width: 200px !important;
  }

  div.catalog_info_button a.course_info_button {
    background-color: #005581;
    color: white;
  }

  div#video_plus_text_container {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  div#catalog-credit-description {
    display: none;
  }

  div#catalog_course_info {
    display: initial;
  }
  #course_view a.course_info_button {
    margin-top: 10px;
  }
}


/* smartphones (landscape) ----------- */
@media screen and (min-width: 321px) and (max-width: 560px) {
  body {
    width: auto;
    margin: auto;
  }

  #page {
    width: auto;
    margin: auto;
    padding-top: 10px;
  }

  #content {
    margin: 0px;
  }

  a.course_info_button,
  div.course_info_message {
    width: 49%;
  }

  /* video object */
  object.mcvid,
  iframe.mcvid {
    width: 450px;
    height: 270px;
  }

  div.course_creator_info {
    display: block;
  }

  div.faculty_photo img {
    margin: 25px 20px 0px 0;
  }

  #course_view {
    margin-left: 20px;
    margin-right: 20px;
  }
}