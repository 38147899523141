@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";


.app-header-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 16px 40px;

  .ucop-wordmark {
    height: 48px;
    display: inline-block;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
      align-items: baseline;
      width: 100%;
      padding-bottom: 25px;
      position: relative;
      left: -20px;
      top: 10px;
    }
  }

  a {
    text-decoration: none;
  }

  h2 {
    font-size: 12pt;
    padding-left: 30px;
    color: $gray_2;
    font-family: $font-book;
    position: relative;
    top: 18px;
    @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
      padding-left: 20px;
      font-size: 12pt;
      width: 12em;
      top: 0;
    }
  }

  @media (min-width: #{$break-small}) and (max-width: #{$break-medium - 1px}) {
    flex-wrap: wrap;
  }
}

.app-header-search-row {
  padding: 12px 28px 12px 28px;
  background-color: $medium_gray;
  display: flex;
  flex-direction: row-reverse;
}

/* header */
div#header_inner {
    clear: both;
    position: relative;
    padding-top: 5px;
    z-index: 99 !important;
    overflow: hidden;
  }

  .dropdown-menu {
    z-index: 1000 !important;
  }

  #breadcrumb_wrapper {
    margin: 0;
    margin-top: 5px;
    padding: 0;
    float: right;
    width: 206px;
    overflow: hidden;
  }

  .courses_breadcrumb {
    width: 190px;
    height: 31px;
    position: relative;
    margin-bottom: 15px;
  }
  .courses_breadcrumb::before {
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    border-top: 15px solid transparent;
    border-bottom: 16px solid transparent;
  }
  .courses_breadcrumb a {
    display: block;
    font-family: "KievitProMed", Arial, Helvetica, sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #ffffff !important;
    padding: 7px 15px 7px 20px;
    text-decoration: none !important;
    font-weight: bold;
  }
  .breadcrumb_blue {
    background-color: #1295d8;
  }
  .breadcrumb_blue::before {
    left: 100%;
    border-left: 15px solid #1295d8;
  }
  .breadcrumb_blue:hover {
    background-color: #005581;
    cursor: pointer;
  }
  .breadcrumb_blue:hover::before {
    left: 100%;
    border-left: 15px solid #005581;
  }

  .breadcrumb_orange {
    background-color: #ffb511;
  }
  .breadcrumb_orange::before {
    left: 100%;
    border-left: 15px solid #ffb511;
  }
  .breadcrumb_orange:hover {
    background-color: #eba710;
    cursor: pointer;
  }
  .breadcrumb_orange:hover::before {
    left: 100%;
    border-left: 15px solid #eba710;
  }