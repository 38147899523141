@font-face {
  font-family: 'KievitProRegular';
  src: url('fonts/KievitWebPro.eot');
  src: url('fonts/KievitWebPro.eot?#iefix') format('embedded-opentype'),
        url('fonts/KievitWebPro.woff') format('woff'),
        url('fonts/KievitOffcPro.ttf') format('truetype');
}

@font-face {
  font-family: 'KievitProBook';
  src: url('fonts/KievitWebPro-Book.eot');
  src: url('fonts/KievitWebPro-Book.eot?#iefix') format('embedded-opentype'),
        url('fonts/KievitWebPro-Book.woff') format('woff'),
        url('fonts/KievitOffcPro-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'KievitProBold';
  src: url('fonts/KievitWebPro-Bold.eot');
  src: url('fonts/KievitWebPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/KievitWebPro-Bold.woff') format('woff'),
        url('fonts/KievitOffcPro-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'KievitProMed';
  src: url('fonts/KievitWebPro-Medi.eot');
  src: url('fonts/KievitWebPro-Medi.eot?#iefix') format('embedded-opentype'),
      url('fonts/KievitWebPro-Medi.woff') format('woff'),
      url('fonts/KievitOffcPro-Medi.ttf') format('truetype');
}

$font: KievitProRegular, Arial, Helvetica, sans-serif;
$font-book: KievitProBook, Arial, Helvetica, sans-serif;
$font-bold: KievitProBold, Arial, Helvetica, sans-serif;
$font-ital: KievitProItal, Arial, Helvetica, sans-serif;
$font-med: KievitProMed, Arial, Helvetica, sans-serif;
