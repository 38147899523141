@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/media";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .modal-main {
    position: fixed;
    background: $white;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    border: 1px solid #c5c5c5;
    h2 {
      color: $black;
      font-family: $font-book;
      font-size: 16pt;
      padding-top: 10px;
    }
    .save {
      width: 100%;
      transform: translate(-50%, -50%);
      position: relative;
      left: 50%;
      top: 1.5em;
    }
    @media (max-width: #{$break-medium}) and (min-width: #{$break-small - 1px}) {
      width: 90%;
      max-width: 450px;

      iframe {
        width: 100%;
        height: 200px;
      }

      .close {
        margin-top: 10px;
      }
    }
  }
  &.display-block {
    display: block;
    animation: fadeIn 0.5s cubic-bezier(0, 0, 0, 0.3);
    background-color:rgba(0,0,0,0.3);
    z-index: 2000;
  }

  &.display-none {
    display: none;
    animation: fadeOut 0.5s cubic-bezier(0, 0, 0, 1);
    background: transparent;
  }

  .close {
    background: white;
    border: 0;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 32px;
    height: 32px;
    opacity: 1;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 25px;
      width: 2px;
      background-color: $black;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  @keyframes fadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.3);
    }
  }
  @keyframes fadeOut {
    0% {
      background: rgba(0, 0, 0, 0.3);
    }
    100% {
      background: rgba(0, 0, 0, 0);
    }
  }
}
